
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import WorkOrderService from "@/services/WorkOrderService";
import store from "@/store";
import Utils from "@/utility/utils";
import InputEmployeeID from "../InputEmployeeID.vue";

const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const workOrderService = new WorkOrderService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
    InputEmployeeID,
  },
  data() {
    return {
      workOrderCompletionPayload: {
        employee_id: "",
        work_order: "",
        operation: "",
        quantity: "",
        to_inventory: "",
        to_work_order: "",
        lot_id: "",
        bin_number: "",
      },
      employeeName: "",
      work_center: "",
      workOrder: {} as any,
      part: {} as any,
      inventoryLocation: {} as any,
      cardHeight: "h-20rem",
      isCompletionEntryLoading: false,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName: "",
      toDescription: "",
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
    };
  },
  async created() {
    await this.fetchLaborControl();
    this.fetchWorkOrderControl();
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      employee_id: {
        required: helpers.withMessage("Employee ID", required),
      },
      employeeName: {
        required: helpers.withMessage("Employee ID", required),
      },
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getLaborFunctionTypes: "laborControl/getFunctionTypes",
      getUserIdAsEmployeeId: "scan/getUserIdAsEmployeeId",
      getUser: "session/getUser",
      getPreventExcessCompletions: "woControl/getPreventExcessCompletions",
    }),
    lotControl(): boolean {
      return this.part.lot_control === "Y";
    },
    binControl(): boolean {
      return this.inventoryLocation.bin_control === "Y";
    },
    isInventoryLocationWOType(): boolean {
      return this.inventoryLocation.type === "WO";
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      addSalesOrders: "salesOrder/addSalesOrders",
      removeSalesOrders: "salesOrder/removeSalesOrders",
      clearSalesOrders: "salesOrder/clearSalesOrders",
      fetchLaborControl: "laborControl/fetchLaborControl",
      fetchLocation: "inventory/fetchLocation",
      getPart: "inventory/fetchPart",
      fetchWorkOrderControl: "woControl/fetchWorkOrderControl",
    }),
    clearAll() {
      this.workOrderCompletionPayload.employee_id = "";
      this.employeeName = "";
      this.work_center = "";
      this.workOrder = {};
      this.part = {};
      this.inventoryLocation = {};
      this.workOrderCompletionPayload.work_order = "";
      this.workOrderCompletionPayload.operation = "";
      this.workOrderCompletionPayload.quantity = "";
      this.workOrderCompletionPayload.to_inventory = "";
      this.workOrderCompletionPayload.to_work_order = "";
      this.workOrderCompletionPayload.lot_id = "";
      this.workOrderCompletionPayload.bin_number = "";

      this.toDescription = "";
    },
    focusInput(refName: string) {
      if (refName) {
        if (refName === "employee_id") {
          (this.$refs[refName] as any).focus();
        } else {
          (this.$refs[refName] as any).$el.focus();
          (this.$refs[refName] as any).$el.select();
        }
      }
    },
    processShopTransaction() {
      if (!this.workOrderCompletionPayload.employee_id) {
        this.handleErrorAndFocus("No employee entered", "employee_id");
        return;
      }
      if (!this.workOrderCompletionPayload.work_order) {
        this.handleErrorAndFocus("No work order entered.", "work_order");
        return;
      }
      if (!this.workOrderCompletionPayload.quantity) {
        this.handleErrorAndFocus("No quantity entered.", "quantity");
        return;
      }
      if (!this.workOrderCompletionPayload.operation) {
        this.handleErrorAndFocus("No operation entered.", "operation");
        return;
      }

      this.isCompletionEntryLoading = true;
      scanService
        .postWorkOrderCompletion(this.workOrderCompletionPayload)
        .then((resp: any) => {
          const notification = {
            dialog: true,
            type: "success",
            message: resp?.message || "Successfully ended job.",
          };
          this.addNotification(notification, { root: true });
          this.clearAll();
        })
        .catch((err) => {
          this.handleErrorAndFocus(
            Utils.parseErrorMessage(err).toString() ||
              "Could not process end job.",
            "employee_id",
          );
        })
        .finally(() => {
          this.isCompletionEntryLoading = false;
        });
    },
    handleWorkOrder() {
      if (!this.workOrderCompletionPayload.work_order) {
        this.handleErrorAndFocus("Work Order ID required.", "wo_id");
        return;
      }

      workOrderService
        .getWorkOrderById(
          this.getClient.client_id,
          this.workOrderCompletionPayload.work_order,
          "", //"wo_id status model_number backflush",
          "",
        )
        .then(async (resp) => {
          this.workOrder = resp as any;
          const wo_id = this.workOrder.wo_id;
          const part_no = this.workOrder.part_no;

          this.validateWorkOrder(this.workOrder);

          if (
            this.workOrder.status === "C" &&
            this.getPreventExcessCompletions
          ) {
            throw new Error(
              `Work Order ${wo_id} has already been completed. No update permitted.`,
            );
          }

          const woWipLocResponse = await this.fetchLocation({
            client: this.getClient,
            id: this.workOrder.wip_loc,
          });

          const woWipLoc = woWipLocResponse.invloc_items[0];

          if (woWipLoc.type !== "WO") {
            throw new Error(
              "A work in process location has not been defined for this work order.",
            );
          }

          this.part = await this.getPart({
            client: this.getClient,
            id: part_no,
          });

          if (this.workOrder.stock_loc) {
            this.workOrderCompletionPayload.to_inventory =
              this.workOrder.stock_loc;
            await this.handleInventory();
          }

          this.workOrderCompletionPayload.work_order = wo_id;

          this.workOrderCompletionPayload.operation =
            this.workOrder.oper_items[
              this.workOrder.oper_items.length - 1
            ].oper;
          this.handleOperation();

          this.focusInput("quantity");
        })
        .catch((err) => {
          this.workOrder = {};
          this.part = {};
          this.handleErrorAndFocus(
            Utils.parseErrorMessage(err).toString(),
            "wo_id",
          );
        });
    },
    handleOperation() {
      if (this.workOrderCompletionPayload.work_order) {
        workOrderService
          .getWorkOrderById(
            this.getClient,
            this.workOrderCompletionPayload.work_order,
            "oper work_center",
            "",
          )
          .then((resp: any) => {
            const oper_items = resp.oper_items;
            if (oper_items) {
              const operation = oper_items.find((item: any) => {
                return item.oper == this.workOrderCompletionPayload.operation;
              });

              if (operation) {
                this.work_center = operation.work_center;
              }
            }
          });
      }
      this.focusInput("to_inventory");
    },
    validateWorkOrder(workOrder: any) {
      const wo_id = workOrder.wo_id;
      const status = workOrder.status;

      if (status === "F") {
        throw Error(
          `Work Order ${wo_id} has been finalized. No update permitted.`,
        );
      } else if (status === "N" || status === "O" || status === "R") {
        throw Error(
          `Work Order ${wo_id} has not been pulled. No update permitted.`,
        );
      }
    },
    async handleInventory() {
      try {
        const response = await this.fetchLocation({
          client: store.getters["session/getClient"],
          id: this.workOrderCompletionPayload.to_inventory,
        });

        if (response.invloc_items.length === 0) {
          throw new Error(
            `Inventory ${this.workOrderCompletionPayload.to_inventory} not found.`,
          );
        }

        this.inventoryLocation = response.invloc_items[0];
        this.toDescription = this.inventoryLocation.desc;
      } catch (err: any) {
        this.toDescription = "";
        this.handleErrorAndFocus(err.message, "to_inventory");
      }
      if (this.isInventoryLocationWOType) {
        this.focusInput("to_work_order");
      } else if (this.lotControl) {
        this.focusInput("lot_id");
      } else if (this.binControl) {
        this.focusInput("bin_number");
      } else {
        this.focusInput("to_description");
      }
    },
    async handleToWorkOrder() {
      try {
        const toWorkOrder = await workOrderService.getWorkOrderById(
          this.getClient,
          this.workOrderCompletionPayload.to_work_order,
          "",
          "",
        );

        this.validateWorkOrder(toWorkOrder);
      } catch (err: any) {
        if (err.response?.data?.message) {
          this.handleErrorAndFocus(err.data.message, "to_work_order");
        } else {
          this.handleErrorAndFocus(err.message, "to_work_order");
        }
      }
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      this.focusInput(this.focusRefName);
    },
  },
  mounted() {
    this.focusInput("employee_id");
    this.clearAll();
  },
});
